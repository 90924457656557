<template>
  <div class="dis_flex flex_dir_col ali_it_cen ju_con_cen">
    <img
      class="w_56 h_56 dis_flex"
      src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220622/mcEqpyrzBZxEjWLeBCBD.png"
    />
    <div class="font_12 col_999 m_t_8">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => '暂无数据',
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
